var olm_exports = {};
var onInitSuccess;
var onInitFail;

var Module = (function() {
  var _scriptDir = typeof document !== 'undefined' && document.currentScript ? document.currentScript.src : undefined;
  return (
function(Module) {
  Module = Module || {};

var a;a||(a=typeof Module !== 'undefined' ? Module : {});var aa;if("undefined"!==typeof window)aa=function(b){window.crypto.getRandomValues(b)};else if(module.exports){var ba=require("crypto");aa=function(b){var c=ba.randomBytes(b.length);b.set(c)};process=global.process}else throw Error("Cannot find global to attach library to");if("undefined"!==typeof OLM_OPTIONS)for(var ca in OLM_OPTIONS)OLM_OPTIONS.hasOwnProperty(ca)&&(a[ca]=OLM_OPTIONS[ca]);
a.onRuntimeInitialized=function(){g=a._olm_error();olm_exports.PRIVATE_KEY_LENGTH=a._olm_pk_private_key_length();onInitSuccess&&onInitSuccess()};a.onAbort=function(b){onInitFail&&onInitFail(b)};var k={},m;for(m in a)a.hasOwnProperty(m)&&(k[m]=a[m]);a.arguments=[];a.thisProgram="./this.program";a.quit=function(b,c){throw c;};a.preRun=[];a.postRun=[];var n=!1,p=!1,da=!1,ea=!1;n="object"===typeof window;p="function"===typeof importScripts;
da="object"===typeof process&&"function"===typeof require&&!n&&!p;ea=!n&&!da&&!p;var r="";function fa(b){return a.locateFile?a.locateFile(b,r):r+b}
if(da){r=__dirname+"/";var ha,ia;a.read=function(b,c){ha||(ha=require("fs"));ia||(ia=require("path"));b=ia.normalize(b);b=ha.readFileSync(b);return c?b:b.toString()};a.readBinary=function(b){b=a.read(b,!0);b.buffer||(b=new Uint8Array(b));assert(b.buffer);return b};1<process.argv.length&&(a.thisProgram=process.argv[1].replace(/\\/g,"/"));a.arguments=process.argv.slice(2);process.on("uncaughtException",function(b){throw b;});process.on("unhandledRejection",function(){process.exit(1)});a.quit=function(b){process.exit(b)};
a.inspect=function(){return"[Emscripten Module object]"}}else if(ea)"undefined"!=typeof read&&(a.read=function(b){return read(b)}),a.readBinary=function(b){if("function"===typeof readbuffer)return new Uint8Array(readbuffer(b));b=read(b,"binary");assert("object"===typeof b);return b},"undefined"!=typeof scriptArgs?a.arguments=scriptArgs:"undefined"!=typeof arguments&&(a.arguments=arguments),"function"===typeof quit&&(a.quit=function(b){quit(b)});else if(n||p)n?document.currentScript&&(r=document.currentScript.src):
r=self.location.href,_scriptDir&&(r=_scriptDir),0!==r.indexOf("blob:")?r=r.substr(0,r.lastIndexOf("/")+1):r="",a.read=function(b){var c=new XMLHttpRequest;c.open("GET",b,!1);c.send(null);return c.responseText},p&&(a.readBinary=function(b){var c=new XMLHttpRequest;c.open("GET",b,!1);c.responseType="arraybuffer";c.send(null);return new Uint8Array(c.response)}),a.readAsync=function(b,c,d){var e=new XMLHttpRequest;e.open("GET",b,!0);e.responseType="arraybuffer";e.onload=function(){200==e.status||0==e.status&&
e.response?c(e.response):d()};e.onerror=d;e.send(null)},a.setWindowTitle=function(b){document.title=b};var ja=a.print||("undefined"!==typeof console?console.log.bind(console):"undefined"!==typeof print?print:null),t=a.printErr||("undefined"!==typeof printErr?printErr:"undefined"!==typeof console&&console.warn.bind(console)||ja);for(m in k)k.hasOwnProperty(m)&&(a[m]=k[m]);k=void 0;function ka(b){var c=u;u=u+b+15&-16;return c}
function la(b){var c=v[w>>2];b=c+b+15&-16;v[w>>2]=b;if(b=b>=x)ma(),b=!0;return b?(v[w>>2]=c,0):c}function na(b){var c;c||(c=16);return Math.ceil(b/c)*c}var oa={"f64-rem":function(b,c){return b%c},"debugger":function(){debugger}},pa=!1;function assert(b,c){b||y("Assertion failed: "+c)}
function z(b){var c="i8";"*"===c.charAt(c.length-1)&&(c="i32");switch(c){case "i1":B[b>>0]=0;break;case "i8":B[b>>0]=0;break;case "i16":qa[b>>1]=0;break;case "i32":v[b>>2]=0;break;case "i64":tempI64=[0,(tempDouble=0,1<=+ra(tempDouble)?0<tempDouble?(sa(+ta(tempDouble/4294967296),4294967295)|0)>>>0:~~+ua((tempDouble-+(~~tempDouble>>>0))/4294967296)>>>0:0)];v[b>>2]=tempI64[0];v[b+4>>2]=tempI64[1];break;case "float":va[b>>2]=0;break;case "double":wa[b>>3]=0;break;default:y("invalid type for setValue: "+
c)}}function xa(b,c){c=c||"i8";"*"===c.charAt(c.length-1)&&(c="i32");switch(c){case "i1":return B[b>>0];case "i8":return B[b>>0];case "i16":return qa[b>>1];case "i32":return v[b>>2];case "i64":return v[b>>2];case "float":return va[b>>2];case "double":return wa[b>>3];default:y("invalid type for getValue: "+c)}return null}
function C(b){var c=a.ALLOC_STACK;if("number"===typeof b){var d=!0;var e=b}else d=!1,e=b.length;c=4==c?f:["function"===typeof ya?ya:ka,za,ka,la][void 0===c?2:c](Math.max(e,1));if(d){var f=c;assert(0==(c&3));for(b=c+(e&-4);f<b;f+=4)v[f>>2]=0;for(b=c+e;f<b;)B[f++>>0]=0;return c}b.subarray||b.slice?D.set(b,c):D.set(new Uint8Array(b),c);return c}
function E(b){var c;if(0===c||!b)return"";for(var d=0,e,f=0;;){e=D[b+f>>0];d|=e;if(0==e&&!c)break;f++;if(c&&f==c)break}c||(c=f);e="";if(128>d){for(;0<c;)d=String.fromCharCode.apply(String,D.subarray(b,b+Math.min(c,1024))),e=e?e+d:d,b+=1024,c-=1024;return e}return F(b)}var Aa="undefined"!==typeof TextDecoder?new TextDecoder("utf8"):void 0;
function F(b){for(var c=D,d=b;c[d];)++d;if(16<d-b&&c.subarray&&Aa)return Aa.decode(c.subarray(b,d));for(d="";;){var e=c[b++];if(!e)return d;if(e&128){var f=c[b++]&63;if(192==(e&224))d+=String.fromCharCode((e&31)<<6|f);else{var l=c[b++]&63;if(224==(e&240))e=(e&15)<<12|f<<6|l;else{var h=c[b++]&63;if(240==(e&248))e=(e&7)<<18|f<<12|l<<6|h;else{var q=c[b++]&63;if(248==(e&252))e=(e&3)<<24|f<<18|l<<12|h<<6|q;else{var H=c[b++]&63;e=(e&1)<<30|f<<24|l<<18|h<<12|q<<6|H}}}65536>e?d+=String.fromCharCode(e):(e-=
65536,d+=String.fromCharCode(55296|e>>10,56320|e&1023))}}else d+=String.fromCharCode(e)}}
function Ba(b,c,d,e){if(!(0<e))return 0;var f=d;e=d+e-1;for(var l=0;l<b.length;++l){var h=b.charCodeAt(l);if(55296<=h&&57343>=h){var q=b.charCodeAt(++l);h=65536+((h&1023)<<10)|q&1023}if(127>=h){if(d>=e)break;c[d++]=h}else{if(2047>=h){if(d+1>=e)break;c[d++]=192|h>>6}else{if(65535>=h){if(d+2>=e)break;c[d++]=224|h>>12}else{if(2097151>=h){if(d+3>=e)break;c[d++]=240|h>>18}else{if(67108863>=h){if(d+4>=e)break;c[d++]=248|h>>24}else{if(d+5>=e)break;c[d++]=252|h>>30;c[d++]=128|h>>24&63}c[d++]=128|h>>18&63}c[d++]=
128|h>>12&63}c[d++]=128|h>>6&63}c[d++]=128|h&63}}c[d]=0;return d-f}function Ca(b){for(var c=0,d=0;d<b.length;++d){var e=b.charCodeAt(d);55296<=e&&57343>=e&&(e=65536+((e&1023)<<10)|b.charCodeAt(++d)&1023);127>=e?++c:c=2047>=e?c+2:65535>=e?c+3:2097151>=e?c+4:67108863>=e?c+5:c+6}return c}"undefined"!==typeof TextDecoder&&new TextDecoder("utf-16le");var buffer,B,D,qa,v,va,wa;
function Da(){a.HEAP8=B=new Int8Array(buffer);a.HEAP16=qa=new Int16Array(buffer);a.HEAP32=v=new Int32Array(buffer);a.HEAPU8=D=new Uint8Array(buffer);a.HEAPU16=new Uint16Array(buffer);a.HEAPU32=new Uint32Array(buffer);a.HEAPF32=va=new Float32Array(buffer);a.HEAPF64=wa=new Float64Array(buffer)}var Ea,u,Fa,Ga,Ha,Ia,w;Ea=u=Fa=Ga=Ha=Ia=w=0;
function ma(){y("Cannot enlarge memory arrays. Either (1) compile with  -s TOTAL_MEMORY=X  with X higher than the current value "+x+", (2) compile with  -s ALLOW_MEMORY_GROWTH=1  which allows increasing the size at runtime, or (3) if you want malloc to return NULL (0) instead of this abort, compile with  -s ABORTING_MALLOC=0 ")}var Ja=a.TOTAL_STACK||65536,x=a.TOTAL_MEMORY||262144;x<Ja&&t("TOTAL_MEMORY should be larger than TOTAL_STACK, was "+x+"! (TOTAL_STACK="+Ja+")");
a.buffer?buffer=a.buffer:("object"===typeof WebAssembly&&"function"===typeof WebAssembly.Memory?(a.wasmMemory=new WebAssembly.Memory({initial:x/65536,maximum:x/65536}),buffer=a.wasmMemory.buffer):buffer=new ArrayBuffer(x),a.buffer=buffer);Da();function Ka(b){for(;0<b.length;){var c=b.shift();if("function"==typeof c)c();else{var d=c.h;"number"===typeof d?void 0===c.c?a.dynCall_v(d):a.dynCall_vi(d,c.c):d(void 0===c.c?null:c.c)}}}var La=[],Ma=[],Na=[],Oa=[],Qa=!1;
function Ra(){var b=a.preRun.shift();La.unshift(b)}function Sa(b,c){for(var d=0;d<b.length;++d)B[c++>>0]=b.charCodeAt(d)}var ra=Math.abs,ua=Math.ceil,ta=Math.floor,sa=Math.min,G=0,Ta=null,Ua=null;a.preloadedImages={};a.preloadedAudios={};function Va(b){return String.prototype.startsWith?b.startsWith("data:application/octet-stream;base64,"):0===b.indexOf("data:application/octet-stream;base64,")}
(function(){function b(){try{if(a.wasmBinary)return new Uint8Array(a.wasmBinary);if(a.readBinary)return a.readBinary(f);throw"both async and sync fetching of the wasm failed";}catch(A){y(A)}}function c(){return a.wasmBinary||!n&&!p||"function"!==typeof fetch?new Promise(function(c){c(b())}):fetch(f,{credentials:"same-origin"}).then(function(b){if(!b.ok)throw"failed to load wasm binary file at '"+f+"'";return b.arrayBuffer()}).catch(function(){return b()})}function d(b){function d(b){q=b.exports;if(q.memory){b=
q.memory;var c=a.buffer;b.byteLength<c.byteLength&&t("the new buffer in mergeMemory is smaller than the previous one. in native wasm, we should grow memory here");c=new Int8Array(c);(new Int8Array(b)).set(c);a.buffer=buffer=b;Da()}a.asm=q;a.usingWasm=!0;G--;a.monitorRunDependencies&&a.monitorRunDependencies(G);0==G&&(null!==Ta&&(clearInterval(Ta),Ta=null),Ua&&(b=Ua,Ua=null,b()))}function e(b){d(b.instance)}function l(b){c().then(function(b){return WebAssembly.instantiate(b,h)}).then(b).catch(function(b){t("failed to asynchronously prepare wasm: "+
b);y(b)})}if("object"!==typeof WebAssembly)return t("no native wasm support detected"),!1;if(!(a.wasmMemory instanceof WebAssembly.Memory))return t("no native wasm Memory in use"),!1;b.memory=a.wasmMemory;h.global={NaN:NaN,Infinity:Infinity};h["global.Math"]=Math;h.env=b;G++;a.monitorRunDependencies&&a.monitorRunDependencies(G);if(a.instantiateWasm)try{return a.instantiateWasm(h,d)}catch(ab){return t("Module.instantiateWasm callback failed with error: "+ab),!1}a.wasmBinary||"function"!==typeof WebAssembly.instantiateStreaming||
Va(f)||"function"!==typeof fetch?l(e):WebAssembly.instantiateStreaming(fetch(f,{credentials:"same-origin"}),h).then(e).catch(function(b){t("wasm streaming compile failed: "+b);t("falling back to ArrayBuffer instantiation");l(e)});return{}}var e="olm.wast",f="olm.wasm",l="olm.temp.asm.js";Va(e)||(e=fa(e));Va(f)||(f=fa(f));Va(l)||(l=fa(l));var h={global:null,env:null,asm2wasm:oa,parent:a},q=null;a.asmPreload=a.asm;var H=a.reallocBuffer;a.reallocBuffer=function(b){if("asmjs"===O)var c=H(b);else a:{var d=
a.usingWasm?65536:16777216;0<b%d&&(b+=d-b%d);d=a.buffer.byteLength;if(a.usingWasm)try{c=-1!==a.wasmMemory.grow((b-d)/65536)?a.buffer=a.wasmMemory.buffer:null;break a}catch(eb){c=null;break a}c=void 0}return c};var O="";a.asm=function(b,c){if(!c.table){b=a.wasmTableSize;void 0===b&&(b=1024);var e=a.wasmMaxTableSize;c.table="object"===typeof WebAssembly&&"function"===typeof WebAssembly.Table?void 0!==e?new WebAssembly.Table({initial:b,maximum:e,element:"anyfunc"}):new WebAssembly.Table({initial:b,element:"anyfunc"}):
Array(b);a.wasmTable=c.table}c.memoryBase||(c.memoryBase=a.STATIC_BASE);c.tableBase||(c.tableBase=0);c=d(c);assert(c,"no binaryen method succeeded.");return c}})();Ea=1024;u=Ea+37168;Ma.push();a.STATIC_BASE=Ea;a.STATIC_BUMP=37168;u+=16;w=ka(4);Fa=Ga=na(u);Ha=Fa+Ja;Ia=na(Ha);v[w>>2]=Ia;function I(b){var c=Array(Ca(b)+1);b=Ba(b,c,0,c.length);c.length=b;return c}a.wasmTableSize=10;a.wasmMaxTableSize=10;a.f={};
a.g={abort:y,enlargeMemory:function(){ma()},getTotalMemory:function(){return x},abortOnCannotGrowMemory:ma,___setErrNo:function(b){a.___errno_location&&(v[a.___errno_location()>>2]=b);return b},_emscripten_memcpy_big:function(b,c,d){D.set(D.subarray(c,c+d),b);return b},DYNAMICTOP_PTR:w,STACKTOP:Ga};var Wa=a.asm(a.f,a.g,buffer);a.asm=Wa;a._free=function(){return a.asm._free.apply(null,arguments)};var ya=a._malloc=function(){return a.asm._malloc.apply(null,arguments)};
a._olm_account=function(){return a.asm._olm_account.apply(null,arguments)};a._olm_account_generate_one_time_keys=function(){return a.asm._olm_account_generate_one_time_keys.apply(null,arguments)};a._olm_account_generate_one_time_keys_random_length=function(){return a.asm._olm_account_generate_one_time_keys_random_length.apply(null,arguments)};a._olm_account_identity_keys=function(){return a.asm._olm_account_identity_keys.apply(null,arguments)};
a._olm_account_identity_keys_length=function(){return a.asm._olm_account_identity_keys_length.apply(null,arguments)};a._olm_account_last_error=function(){return a.asm._olm_account_last_error.apply(null,arguments)};a._olm_account_mark_keys_as_published=function(){return a.asm._olm_account_mark_keys_as_published.apply(null,arguments)};a._olm_account_max_number_of_one_time_keys=function(){return a.asm._olm_account_max_number_of_one_time_keys.apply(null,arguments)};
a._olm_account_one_time_keys=function(){return a.asm._olm_account_one_time_keys.apply(null,arguments)};a._olm_account_one_time_keys_length=function(){return a.asm._olm_account_one_time_keys_length.apply(null,arguments)};a._olm_account_sign=function(){return a.asm._olm_account_sign.apply(null,arguments)};a._olm_account_signature_length=function(){return a.asm._olm_account_signature_length.apply(null,arguments)};a._olm_account_size=function(){return a.asm._olm_account_size.apply(null,arguments)};
a._olm_clear_account=function(){return a.asm._olm_clear_account.apply(null,arguments)};a._olm_clear_inbound_group_session=function(){return a.asm._olm_clear_inbound_group_session.apply(null,arguments)};a._olm_clear_outbound_group_session=function(){return a.asm._olm_clear_outbound_group_session.apply(null,arguments)};a._olm_clear_pk_decryption=function(){return a.asm._olm_clear_pk_decryption.apply(null,arguments)};
a._olm_clear_pk_encryption=function(){return a.asm._olm_clear_pk_encryption.apply(null,arguments)};a._olm_clear_session=function(){return a.asm._olm_clear_session.apply(null,arguments)};a._olm_clear_utility=function(){return a.asm._olm_clear_utility.apply(null,arguments)};a._olm_create_account=function(){return a.asm._olm_create_account.apply(null,arguments)};a._olm_create_account_random_length=function(){return a.asm._olm_create_account_random_length.apply(null,arguments)};
a._olm_create_inbound_session=function(){return a.asm._olm_create_inbound_session.apply(null,arguments)};a._olm_create_inbound_session_from=function(){return a.asm._olm_create_inbound_session_from.apply(null,arguments)};a._olm_create_outbound_session=function(){return a.asm._olm_create_outbound_session.apply(null,arguments)};a._olm_create_outbound_session_random_length=function(){return a.asm._olm_create_outbound_session_random_length.apply(null,arguments)};
a._olm_decrypt=function(){return a.asm._olm_decrypt.apply(null,arguments)};a._olm_decrypt_max_plaintext_length=function(){return a.asm._olm_decrypt_max_plaintext_length.apply(null,arguments)};a._olm_ed25519_verify=function(){return a.asm._olm_ed25519_verify.apply(null,arguments)};a._olm_encrypt=function(){return a.asm._olm_encrypt.apply(null,arguments)};a._olm_encrypt_message_length=function(){return a.asm._olm_encrypt_message_length.apply(null,arguments)};
a._olm_encrypt_message_type=function(){return a.asm._olm_encrypt_message_type.apply(null,arguments)};a._olm_encrypt_random_length=function(){return a.asm._olm_encrypt_random_length.apply(null,arguments)};a._olm_error=function(){return a.asm._olm_error.apply(null,arguments)};a._olm_export_inbound_group_session=function(){return a.asm._olm_export_inbound_group_session.apply(null,arguments)};
a._olm_export_inbound_group_session_length=function(){return a.asm._olm_export_inbound_group_session_length.apply(null,arguments)};a._olm_get_library_version=function(){return a.asm._olm_get_library_version.apply(null,arguments)};a._olm_group_decrypt=function(){return a.asm._olm_group_decrypt.apply(null,arguments)};a._olm_group_decrypt_max_plaintext_length=function(){return a.asm._olm_group_decrypt_max_plaintext_length.apply(null,arguments)};
a._olm_group_encrypt=function(){return a.asm._olm_group_encrypt.apply(null,arguments)};a._olm_group_encrypt_message_length=function(){return a.asm._olm_group_encrypt_message_length.apply(null,arguments)};a._olm_import_inbound_group_session=function(){return a.asm._olm_import_inbound_group_session.apply(null,arguments)};a._olm_inbound_group_session=function(){return a.asm._olm_inbound_group_session.apply(null,arguments)};
a._olm_inbound_group_session_first_known_index=function(){return a.asm._olm_inbound_group_session_first_known_index.apply(null,arguments)};a._olm_inbound_group_session_id=function(){return a.asm._olm_inbound_group_session_id.apply(null,arguments)};a._olm_inbound_group_session_id_length=function(){return a.asm._olm_inbound_group_session_id_length.apply(null,arguments)};a._olm_inbound_group_session_is_verified=function(){return a.asm._olm_inbound_group_session_is_verified.apply(null,arguments)};
a._olm_inbound_group_session_last_error=function(){return a.asm._olm_inbound_group_session_last_error.apply(null,arguments)};a._olm_inbound_group_session_size=function(){return a.asm._olm_inbound_group_session_size.apply(null,arguments)};a._olm_init_inbound_group_session=function(){return a.asm._olm_init_inbound_group_session.apply(null,arguments)};a._olm_init_outbound_group_session=function(){return a.asm._olm_init_outbound_group_session.apply(null,arguments)};
a._olm_init_outbound_group_session_random_length=function(){return a.asm._olm_init_outbound_group_session_random_length.apply(null,arguments)};a._olm_matches_inbound_session=function(){return a.asm._olm_matches_inbound_session.apply(null,arguments)};a._olm_matches_inbound_session_from=function(){return a.asm._olm_matches_inbound_session_from.apply(null,arguments)};a._olm_outbound_group_session=function(){return a.asm._olm_outbound_group_session.apply(null,arguments)};
a._olm_outbound_group_session_id=function(){return a.asm._olm_outbound_group_session_id.apply(null,arguments)};a._olm_outbound_group_session_id_length=function(){return a.asm._olm_outbound_group_session_id_length.apply(null,arguments)};a._olm_outbound_group_session_key=function(){return a.asm._olm_outbound_group_session_key.apply(null,arguments)};a._olm_outbound_group_session_key_length=function(){return a.asm._olm_outbound_group_session_key_length.apply(null,arguments)};
a._olm_outbound_group_session_last_error=function(){return a.asm._olm_outbound_group_session_last_error.apply(null,arguments)};a._olm_outbound_group_session_message_index=function(){return a.asm._olm_outbound_group_session_message_index.apply(null,arguments)};a._olm_outbound_group_session_size=function(){return a.asm._olm_outbound_group_session_size.apply(null,arguments)};a._olm_pickle_account=function(){return a.asm._olm_pickle_account.apply(null,arguments)};
a._olm_pickle_account_length=function(){return a.asm._olm_pickle_account_length.apply(null,arguments)};a._olm_pickle_inbound_group_session=function(){return a.asm._olm_pickle_inbound_group_session.apply(null,arguments)};a._olm_pickle_inbound_group_session_length=function(){return a.asm._olm_pickle_inbound_group_session_length.apply(null,arguments)};a._olm_pickle_outbound_group_session=function(){return a.asm._olm_pickle_outbound_group_session.apply(null,arguments)};
a._olm_pickle_outbound_group_session_length=function(){return a.asm._olm_pickle_outbound_group_session_length.apply(null,arguments)};a._olm_pickle_pk_decryption=function(){return a.asm._olm_pickle_pk_decryption.apply(null,arguments)};a._olm_pickle_pk_decryption_length=function(){return a.asm._olm_pickle_pk_decryption_length.apply(null,arguments)};a._olm_pickle_session=function(){return a.asm._olm_pickle_session.apply(null,arguments)};
a._olm_pickle_session_length=function(){return a.asm._olm_pickle_session_length.apply(null,arguments)};a._olm_pk_ciphertext_length=function(){return a.asm._olm_pk_ciphertext_length.apply(null,arguments)};a._olm_pk_decrypt=function(){return a.asm._olm_pk_decrypt.apply(null,arguments)};a._olm_pk_decryption=function(){return a.asm._olm_pk_decryption.apply(null,arguments)};a._olm_pk_decryption_last_error=function(){return a.asm._olm_pk_decryption_last_error.apply(null,arguments)};
a._olm_pk_decryption_size=function(){return a.asm._olm_pk_decryption_size.apply(null,arguments)};a._olm_pk_encrypt=function(){return a.asm._olm_pk_encrypt.apply(null,arguments)};a._olm_pk_encrypt_random_length=function(){return a.asm._olm_pk_encrypt_random_length.apply(null,arguments)};a._olm_pk_encryption=function(){return a.asm._olm_pk_encryption.apply(null,arguments)};a._olm_pk_encryption_last_error=function(){return a.asm._olm_pk_encryption_last_error.apply(null,arguments)};
a._olm_pk_encryption_set_recipient_key=function(){return a.asm._olm_pk_encryption_set_recipient_key.apply(null,arguments)};a._olm_pk_encryption_size=function(){return a.asm._olm_pk_encryption_size.apply(null,arguments)};a._olm_pk_generate_key=function(){return a.asm._olm_pk_generate_key.apply(null,arguments)};a._olm_pk_generate_key_random_length=function(){return a.asm._olm_pk_generate_key_random_length.apply(null,arguments)};
a._olm_pk_get_private_key=function(){return a.asm._olm_pk_get_private_key.apply(null,arguments)};a._olm_pk_key_from_private=function(){return a.asm._olm_pk_key_from_private.apply(null,arguments)};a._olm_pk_key_length=function(){return a.asm._olm_pk_key_length.apply(null,arguments)};a._olm_pk_mac_length=function(){return a.asm._olm_pk_mac_length.apply(null,arguments)};a._olm_pk_max_plaintext_length=function(){return a.asm._olm_pk_max_plaintext_length.apply(null,arguments)};
a._olm_pk_private_key_length=function(){return a.asm._olm_pk_private_key_length.apply(null,arguments)};a._olm_remove_one_time_keys=function(){return a.asm._olm_remove_one_time_keys.apply(null,arguments)};a._olm_session=function(){return a.asm._olm_session.apply(null,arguments)};a._olm_session_has_received_message=function(){return a.asm._olm_session_has_received_message.apply(null,arguments)};a._olm_session_id=function(){return a.asm._olm_session_id.apply(null,arguments)};
a._olm_session_id_length=function(){return a.asm._olm_session_id_length.apply(null,arguments)};a._olm_session_last_error=function(){return a.asm._olm_session_last_error.apply(null,arguments)};a._olm_session_size=function(){return a.asm._olm_session_size.apply(null,arguments)};a._olm_sha256=function(){return a.asm._olm_sha256.apply(null,arguments)};a._olm_sha256_length=function(){return a.asm._olm_sha256_length.apply(null,arguments)};
a._olm_unpickle_account=function(){return a.asm._olm_unpickle_account.apply(null,arguments)};a._olm_unpickle_inbound_group_session=function(){return a.asm._olm_unpickle_inbound_group_session.apply(null,arguments)};a._olm_unpickle_outbound_group_session=function(){return a.asm._olm_unpickle_outbound_group_session.apply(null,arguments)};a._olm_unpickle_pk_decryption=function(){return a.asm._olm_unpickle_pk_decryption.apply(null,arguments)};
a._olm_unpickle_session=function(){return a.asm._olm_unpickle_session.apply(null,arguments)};a._olm_utility=function(){return a.asm._olm_utility.apply(null,arguments)};a._olm_utility_last_error=function(){return a.asm._olm_utility_last_error.apply(null,arguments)};a._olm_utility_size=function(){return a.asm._olm_utility_size.apply(null,arguments)};
var za=a.stackAlloc=function(){return a.asm.stackAlloc.apply(null,arguments)},Xa=a.stackRestore=function(){return a.asm.stackRestore.apply(null,arguments)},Ya=a.stackSave=function(){return a.asm.stackSave.apply(null,arguments)};a.asm=Wa;a.ALLOC_STACK=1;a.then=function(b){if(a.calledRun)b(a);else{var c=a.onRuntimeInitialized;a.onRuntimeInitialized=function(){c&&c();b(a)}}return a};Ua=function Za(){a.calledRun||$a();a.calledRun||(Ua=Za)};
function $a(){function b(){if(!a.calledRun&&(a.calledRun=!0,!pa)){Qa||(Qa=!0,Ka(Ma));Ka(Na);if(a.onRuntimeInitialized)a.onRuntimeInitialized();if(a.postRun)for("function"==typeof a.postRun&&(a.postRun=[a.postRun]);a.postRun.length;){var b=a.postRun.shift();Oa.unshift(b)}Ka(Oa)}}if(!(0<G)){if(a.preRun)for("function"==typeof a.preRun&&(a.preRun=[a.preRun]);a.preRun.length;)Ra();Ka(La);0<G||a.calledRun||(a.setStatus?(a.setStatus("Running..."),setTimeout(function(){setTimeout(function(){a.setStatus("")},
1);b()},1)):b())}}a.run=$a;function y(b){if(a.onAbort)a.onAbort(b);void 0!==b?(ja(b),t(b),b=JSON.stringify(b)):b="";pa=!0;throw"abort("+b+"). Build with -s ASSERTIONS=1 for more info.";}a.abort=y;if(a.preInit)for("function"==typeof a.preInit&&(a.preInit=[a.preInit]);0<a.preInit.length;)a.preInit.pop()();a.noExitRuntime=!0;$a();function J(){var b=a._olm_outbound_group_session_size();this.b=K(b);this.a=a._olm_outbound_group_session(this.b)}
function L(b){return function(){var c=b.apply(this,arguments);if(c===g)throw c=E(a._olm_outbound_group_session_last_error(arguments[0])),Error("OLM."+c);return c}}J.prototype.free=function(){a._olm_clear_outbound_group_session(this.a);M(this.a)};J.prototype.pickle=N(function(b){b=I(b);var c=L(a._olm_pickle_outbound_group_session_length)(this.a),d=C(b),e=C(c+1);try{L(a._olm_pickle_outbound_group_session)(this.a,d,b.length,e,c)}finally{for(P(d,b.length),c=0;c<b.length;c++)b[c]=0}return E(e)});
J.prototype.unpickle=N(function(b,c){b=I(b);var d=C(b);c=I(c);var e=C(c);try{L(a._olm_unpickle_outbound_group_session)(this.a,d,b.length,e,c.length)}finally{for(P(d,b.length),d=0;d<b.length;d++)b[d]=0}});J.prototype.create=N(function(){var b=L(a._olm_init_outbound_group_session_random_length)(this.a),c=Q(b);L(a._olm_init_outbound_group_session)(this.a,c,b)});
J.prototype.encrypt=function(b){try{var c=Ca(b);var d=L(a._olm_group_encrypt_message_length)(this.a,c);var e=K(c+1);Ba(b,D,e,c+1);var f=K(d+1);L(a._olm_group_encrypt)(this.a,e,c,f,d);z(f+d);return F(f)}finally{void 0!==e&&(P(e,c+1),M(e)),void 0!==f&&M(f)}};J.prototype.session_id=N(function(){var b=L(a._olm_outbound_group_session_id_length)(this.a),c=C(b+1);L(a._olm_outbound_group_session_id)(this.a,c,b);return E(c)});
J.prototype.session_key=N(function(){var b=L(a._olm_outbound_group_session_key_length)(this.a),c=C(b+1);L(a._olm_outbound_group_session_key)(this.a,c,b);var d=E(c);P(c,b);return d});J.prototype.message_index=function(){return L(a._olm_outbound_group_session_message_index)(this.a)};olm_exports.OutboundGroupSession=J;function R(){var b=a._olm_inbound_group_session_size();this.b=K(b);this.a=a._olm_inbound_group_session(this.b)}
function S(b){return function(){var c=b.apply(this,arguments);if(c===g)throw c=E(a._olm_inbound_group_session_last_error(arguments[0])),Error("OLM."+c);return c}}R.prototype.free=function(){a._olm_clear_inbound_group_session(this.a);M(this.a)};R.prototype.pickle=N(function(b){b=I(b);var c=S(a._olm_pickle_inbound_group_session_length)(this.a),d=C(b),e=C(c+1);try{S(a._olm_pickle_inbound_group_session)(this.a,d,b.length,e,c)}finally{for(P(d,b.length),c=0;c<b.length;c++)b[c]=0}return E(e)});
R.prototype.unpickle=N(function(b,c){b=I(b);var d=C(b);c=I(c);var e=C(c);try{S(a._olm_unpickle_inbound_group_session)(this.a,d,b.length,e,c.length)}finally{for(P(d,b.length),d=0;d<b.length;d++)b[d]=0}});R.prototype.create=N(function(b){b=I(b);var c=C(b);try{S(a._olm_init_inbound_group_session)(this.a,c,b.length)}finally{for(P(c,b.length),c=0;c<b.length;c++)b[c]=0}});
R.prototype.import_session=N(function(b){b=I(b);var c=C(b);try{S(a._olm_import_inbound_group_session)(this.a,c,b.length)}finally{for(P(c,b.length),c=0;c<b.length;c++)b[c]=0}});R.prototype.decrypt=N(function(b){try{var c=K(b.length);Sa(b,c);var d=S(a._olm_group_decrypt_max_plaintext_length)(this.a,c,b.length);Sa(b,c);var e=K(d+1);var f=C(4);var l=S(a._olm_group_decrypt)(this.a,c,b.length,e,d,f);z(e+l);return{plaintext:F(e),message_index:xa(f,"i32")}}finally{void 0!==c&&M(c),void 0!==e&&(P(e,l+1),M(e))}});
R.prototype.session_id=N(function(){var b=S(a._olm_inbound_group_session_id_length)(this.a),c=C(b+1);S(a._olm_inbound_group_session_id)(this.a,c,b);return E(c)});R.prototype.first_known_index=N(function(){return S(a._olm_inbound_group_session_first_known_index)(this.a)});R.prototype.export_session=N(function(b){var c=S(a._olm_export_inbound_group_session_length)(this.a),d=C(c+1);L(a._olm_export_inbound_group_session)(this.a,d,c,b);b=E(d);P(d,c);return b});olm_exports.InboundGroupSession=R;
function bb(){var b=a._olm_pk_encryption_size();this.b=K(b);this.a=a._olm_pk_encryption(this.b)}function T(b){return function(){var c=b.apply(this,arguments);if(c===g)throw c=E(a._olm_pk_encryption_last_error(arguments[0])),Error("OLM."+c);return c}}bb.prototype.free=function(){a._olm_clear_pk_encryption(this.a);M(this.a)};bb.prototype.set_recipient_key=N(function(b){b=I(b);var c=C(b);T(a._olm_pk_encryption_set_recipient_key)(this.a,c,b.length)});
bb.prototype.encrypt=N(function(b){try{var c=Ca(b);var d=K(c+1);Ba(b,D,d,c+1);var e=T(a._olm_pk_encrypt_random_length)();var f=Q(e);var l=T(a._olm_pk_ciphertext_length)(this.a,c);var h=K(l+1);var q=T(a._olm_pk_mac_length)(this.a),H=C(q+1);z(H+q);var O=T(a._olm_pk_key_length)(),A=C(O+1);z(A+O);T(a._olm_pk_encrypt)(this.a,d,c,h,l,H,q,A,O,f,e);z(h+l);return{ciphertext:F(h),mac:E(H),ephemeral:E(A)}}finally{void 0!==f&&P(f,e),void 0!==d&&(P(d,c+1),M(d)),void 0!==h&&M(h)}});
function U(){var b=a._olm_pk_decryption_size();this.b=K(b);this.a=a._olm_pk_decryption(this.b)}function V(b){return function(){var c=b.apply(this,arguments);if(c===g)throw c=E(a._olm_pk_decryption_last_error(arguments[0])),Error("OLM."+c);return c}}U.prototype.free=function(){a._olm_clear_pk_decryption(this.a);M(this.a)};
U.prototype.init_with_private_key=N(function(b){var c=C(b.length);a.HEAPU8.set(b,c);var d=V(a._olm_pk_key_length)(),e=C(d+1);try{V(a._olm_pk_key_from_private)(this.a,e,d,c,b.length)}finally{P(c,b.length)}return E(e)});U.prototype.generate_key=N(function(){var b=V(a._olm_pk_private_key_length)(),c=Q(b),d=V(a._olm_pk_key_length)(),e=C(d+1);try{V(a._olm_pk_key_from_private)(this.a,e,d,c,b)}finally{P(c,b)}return E(e)});
U.prototype.get_private_key=N(function(){var b=T(a._olm_pk_private_key_length)(),c=C(b);V(a._olm_pk_get_private_key)(this.a,c,b);var d=new Uint8Array(new Uint8Array(a.HEAPU8.buffer,c,b));P(c,b);return d});U.prototype.pickle=N(function(b){b=I(b);var c=V(a._olm_pickle_pk_decryption_length)(this.a),d=C(b),e=C(c+1);try{V(a._olm_pickle_pk_decryption)(this.a,d,b.length,e,c)}finally{for(P(d,b.length),c=0;c<b.length;c++)b[c]=0}return E(e)});
U.prototype.unpickle=N(function(b,c){b=I(b);var d=C(b),e=I(c),f=C(e),l=V(a._olm_pk_key_length)();c=C(l+1);try{V(a._olm_unpickle_pk_decryption)(this.a,d,b.length,f,e.length,c,l)}finally{for(P(d,b.length),d=0;d<b.length;d++)b[d]=0}return E(c)});
U.prototype.decrypt=N(function(b,c,d){try{var e=Ca(d);var f=K(e+1);Ba(d,D,f,e+1);var l=I(b),h=C(l),q=I(c),H=C(q);var O=V(a._olm_pk_max_plaintext_length)(this.a,e);var A=K(O+1);var Pa=V(a._olm_pk_decrypt)(this.a,h,l.length,H,q.length,f,e,A,O);z(A+Pa);return F(A)}finally{void 0!==A&&(P(A,Pa+1),M(A)),void 0!==f&&M(f)}});var K=a._malloc,M=a._free,g;function Q(b){var c=C(b);b=new Uint8Array(a.HEAPU8.buffer,c,b);aa(b);return c}
function N(b){return function(){var c=Ya();try{return b.apply(this,arguments)}finally{Xa(c)}}}function P(b,c){for(;0<c--;)a.HEAP8[b++]=0}function W(){var b=a._olm_account_size();this.b=K(b);this.a=a._olm_account(this.b)}function X(b){return function(){var c=b.apply(this,arguments);if(c===g)throw c=E(a._olm_account_last_error(arguments[0])),Error("OLM."+c);return c}}W.prototype.free=function(){a._olm_clear_account(this.a);M(this.a)};
W.prototype.create=N(function(){var b=X(a._olm_create_account_random_length)(this.a),c=Q(b);X(a._olm_create_account)(this.a,c,b)});W.prototype.identity_keys=N(function(){var b=X(a._olm_account_identity_keys_length)(this.a),c=C(b+1);X(a._olm_account_identity_keys)(this.a,c,b);return E(c)});W.prototype.sign=N(function(b){var c=X(a._olm_account_signature_length)(this.a);b=I(b);var d=C(b),e=C(c+1);try{X(a._olm_account_sign)(this.a,d,b.length,e,c)}finally{for(P(d,b.length),c=0;c<b.length;c++)b[c]=0}return E(e)});
W.prototype.one_time_keys=N(function(){var b=X(a._olm_account_one_time_keys_length)(this.a),c=C(b+1);X(a._olm_account_one_time_keys)(this.a,c,b);return E(c)});W.prototype.mark_keys_as_published=N(function(){X(a._olm_account_mark_keys_as_published)(this.a)});W.prototype.max_number_of_one_time_keys=N(function(){return X(a._olm_account_max_number_of_one_time_keys)(this.a)});
W.prototype.generate_one_time_keys=N(function(b){var c=X(a._olm_account_generate_one_time_keys_random_length)(this.a,b),d=Q(c);X(a._olm_account_generate_one_time_keys)(this.a,b,d,c)});W.prototype.remove_one_time_keys=N(function(b){X(a._olm_remove_one_time_keys)(this.a,b.a)});W.prototype.pickle=N(function(b){b=I(b);var c=X(a._olm_pickle_account_length)(this.a),d=C(b),e=C(c+1);try{X(a._olm_pickle_account)(this.a,d,b.length,e,c)}finally{for(P(d,b.length),c=0;c<b.length;c++)b[c]=0}return E(e)});
W.prototype.unpickle=N(function(b,c){b=I(b);var d=C(b);c=I(c);var e=C(c);try{X(a._olm_unpickle_account)(this.a,d,b.length,e,c.length)}finally{for(P(d,b.length),d=0;d<b.length;d++)b[d]=0}});function Y(){var b=a._olm_session_size();this.b=K(b);this.a=a._olm_session(this.b)}function Z(b){return function(){var c=b.apply(this,arguments);if(c===g)throw c=E(a._olm_session_last_error(arguments[0])),Error("OLM."+c);return c}}Y.prototype.free=function(){a._olm_clear_session(this.a);M(this.a)};
Y.prototype.pickle=N(function(b){b=I(b);var c=Z(a._olm_pickle_session_length)(this.a),d=C(b),e=C(c+1);try{Z(a._olm_pickle_session)(this.a,d,b.length,e,c)}finally{for(P(d,b.length),c=0;c<b.length;c++)b[c]=0}return E(e)});Y.prototype.unpickle=N(function(b,c){b=I(b);var d=C(b);c=I(c);var e=C(c);try{Z(a._olm_unpickle_session)(this.a,d,b.length,e,c.length)}finally{for(P(d,b.length),d=0;d<b.length;d++)b[d]=0}});
Y.prototype.create_outbound=N(function(b,c,d){var e=Z(a._olm_create_outbound_session_random_length)(this.a),f=Q(e);c=I(c);d=I(d);var l=C(c),h=C(d);try{Z(a._olm_create_outbound_session)(this.a,b.a,l,c.length,h,d.length,f,e)}finally{P(f,e)}});Y.prototype.create_inbound=N(function(b,c){c=I(c);var d=C(c);try{Z(a._olm_create_inbound_session)(this.a,b.a,d,c.length)}finally{for(P(d,c.length),b=0;b<c.length;b++)c[b]=0}});
Y.prototype.create_inbound_from=N(function(b,c,d){c=I(c);var e=C(c);d=I(d);var f=C(d);try{Z(a._olm_create_inbound_session_from)(this.a,b.a,e,c.length,f,d.length)}finally{for(P(f,d.length),b=0;b<d.length;b++)d[b]=0}});Y.prototype.session_id=N(function(){var b=Z(a._olm_session_id_length)(this.a),c=C(b+1);Z(a._olm_session_id)(this.a,c,b);return E(c)});Y.prototype.has_received_message=function(){return Z(a._olm_session_has_received_message)(this.a)?!0:!1};
Y.prototype.matches_inbound=N(function(b){b=I(b);var c=C(b);return Z(a._olm_matches_inbound_session)(this.a,c,b.length)?!0:!1});Y.prototype.matches_inbound_from=N(function(b,c){b=I(b);var d=C(b);c=I(c);var e=C(c);return Z(a._olm_matches_inbound_session_from)(this.a,d,b.length,e,c.length)?!0:!1});
Y.prototype.encrypt=N(function(b){try{var c=Z(a._olm_encrypt_random_length)(this.a);var d=Z(a._olm_encrypt_message_type)(this.a);var e=Ca(b);var f=Z(a._olm_encrypt_message_length)(this.a,e);var l=Q(c);var h=K(e+1);Ba(b,D,h,e+1);var q=K(f+1);Z(a._olm_encrypt)(this.a,h,e,l,c,q,f);z(q+f);return{type:d,body:F(q)}}finally{void 0!==l&&P(l,c),void 0!==h&&(P(h,e+1),M(h)),void 0!==q&&M(q)}});
Y.prototype.decrypt=N(function(b,c){try{var d=K(c.length);Sa(c,d);var e=Z(a._olm_decrypt_max_plaintext_length)(this.a,b,d,c.length);Sa(c,d);var f=K(e+1);var l=Z(a._olm_decrypt)(this.a,b,d,c.length,f,e);z(f+l);return F(f)}finally{void 0!==d&&M(d),void 0!==f&&(P(f,e+1),M(f))}});function cb(){var b=a._olm_utility_size();this.b=K(b);this.a=a._olm_utility(this.b)}
function db(b){return function(){var c=b.apply(this,arguments);if(c===g)throw c=E(a._olm_utility_last_error(arguments[0])),Error("OLM."+c);return c}}cb.prototype.free=function(){a._olm_clear_utility(this.a);M(this.a)};cb.prototype.sha256=N(function(b){var c=db(a._olm_sha256_length)(this.a);b=I(b);var d=C(b),e=C(c+1);try{db(a._olm_sha256)(this.a,d,b.length,e,c)}finally{for(P(d,b.length),c=0;c<b.length;c++)b[c]=0}return E(e)});
cb.prototype.ed25519_verify=N(function(b,c,d){b=I(b);var e=C(b);c=I(c);var f=C(c);d=I(d);var l=C(d);try{db(a._olm_ed25519_verify)(this.a,e,b.length,f,c.length,l,d.length)}finally{for(P(f,c.length),b=0;b<c.length;b++)c[b]=0}});olm_exports.Account=W;olm_exports.Session=Y;olm_exports.Utility=cb;olm_exports.PkEncryption=bb;olm_exports.PkDecryption=U;olm_exports.get_library_version=N(function(){var b=C(3);a._olm_get_library_version(b,b+1,b+2);return[xa(b,"i8"),xa(b+1,"i8"),xa(b+2,"i8")]});


  return Module;
}
);
})();
if (typeof exports === 'object' && typeof module === 'object')
    module.exports = Module;
  else if (typeof define === 'function' && define['amd'])
    define([], function() { return Module; });
  else if (typeof exports === 'object')
    exports["Module"] = Module;
  var olmInitPromise;

olm_exports['init'] = function(opts) {
    if (olmInitPromise) return olmInitPromise;

    if (opts) OLM_OPTIONS = opts;

    olmInitPromise = new Promise(function(resolve, reject) {
        onInitSuccess = function() {
            resolve();
        };
        onInitFail = function(err) {
            reject(err);
        };
        Module();
    });
    return olmInitPromise;
};

if (typeof(window) !== 'undefined') {
    // We've been imported directly into a browser. Define the global 'Olm' object.
    // (we do this even if module.exports was defined, because it's useful to have
    // Olm in the global scope for browserified and webpacked apps.)
    window["Olm"] = olm_exports;
}

if (typeof module === 'object') {
    // Emscripten sets the module exports to be its module
    // with wrapped c functions. Clobber it with our higher
    // level wrapper class.
    module.exports = olm_exports;
}
